import { IoMdStar } from 'react-icons/io'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import Link from 'next/link'

interface Props {
  title: ReactNode
  body: ReactNode
  name: string
  association: string
  stars: number
  source: string
  to: string
}

export const ReviewCard = ({ title, name, association, stars, source, to }: Props) => {
  return (
    <Container href={to} target={'_blank'}>
      <div className="review-body">
        <span className="title">{title}</span>
      </div>
      <div className="review-header">
        <div>
          <div className="name">{name}</div>
          <div className="association">{association}</div>
        </div>
        <div className="stars">
          <div>
            {Array(stars)
              .fill(0)
              .map((_, i) => (
                <IoMdStar key={i} />
              ))}
          </div>
          <span className="rating-label">{source}</span>
        </div>
      </div>
    </Container>
  )
}

const Container = styled(Link)`
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  border: 1px solid ${({ theme }) => theme.primaryLighter};
  flex-basis: 40rem;

  border-radius: 0.25rem;

  background-color: ${({ theme }) => theme.surfaceContrast};

  @media (max-width: 1400px) {
    flex-basis: 30rem;
  }

  @media (max-width: 1000px) {
    flex-basis: 100%;
  }

  transition: border 0.1s ease-in-out;
  :hover {
    border: 1px solid ${({ theme }) => theme.primary};
  }

  .review-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 1rem 0;
    margin-top: 1rem;
  }

  .name {
    font-weight: normal;
    font-size: 1rem;
  }

  .association {
    margin-top: 0.25rem;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.onBackgroundLight};
  }

  .stars {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      color: ${({ theme }) => theme.yellow};
      font-size: 1.25rem;
    }

    .rating-label {
      margin-top: 0.5rem;
      display: block;
      width: 100%;
      font-size: 0.75rem;
      text-align: right;
    }
  }

  .review-body {
    .bold {
      font-weight: 600;
    }

    .title {
      display: block;
      font-size: 1.35rem;
    }

    font-size: 1rem;
    line-height: 1.5;
  }
`
