'use client'
import styled from '@emotion/styled'
import { ReactNode, useRef, useState } from 'react'

interface Props {
  items: {
    title: string
    body: ReactNode
  }[]
}

export const Accordion = ({ items }: Props) => {
  return (
    <Container>
      {items.map((item, index) => (
        <Entry key={index + item.title} title={item.title} body={item.body} />
      ))}
    </Container>
  )
}

const Entry = ({ body, title }: { body: ReactNode; title: string }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLParagraphElement>(null)

  const handleToggle = () => {
    setOpen(!open)

    if (ref.current) {
      ref.current.style.maxHeight = open ? '0' : `${ref.current.scrollHeight}px`
    }
  }

  return (
    <Item onClick={handleToggle}>
      <Title>{title}</Title>
      <Body ref={ref} open={open}>
        {body}
      </Body>
    </Item>
  )
}

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Item = styled.div`
  margin-bottom: 1rem;
  padding: 1rem 0;

  width: 100%;

  cursor: pointer;
  transition: all 0.2s ease;

  border-bottom: 1px solid ${({ theme }) => theme.onBackgroundLightest};
`

const Title = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`

const Body = styled.p<{ open: boolean }>`
  max-height: 0;
    line-height: 1.5;
  overflow: hidden;
  transition:
    max-height 0.2s ease-out,
    opacity 0.1s ease-out;

    opacity: ${({ open }) => (open ? 1 : 0)};

  font-size: 1.2rem;
  color: ${({ theme }) => theme.onBackgroundSemiLight};
`
