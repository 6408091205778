import { Button } from '@common/components/Button'
import styled from '@emotion/styled'
import { ResponsivePadding } from './ResponsivePadding'
import Link from 'next/link'

export const Hero = () => {
  return (
    <Container>
      <h1>
        Den <span>enkla</span> och <span>säkra</span> plattformen för din BRF.
      </h1>
      <p>Booet underlättar för hela föreningen med en rad smarta verktyg och funktioner.</p>
      <ButtonContainer>
        <Link href="/start">
          <Button>Kom igång kostnadsfritt</Button>
        </Link>
        <Link href="/demo">
          <Button variant="tertiary">Boka demo</Button>
        </Link>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  > p {
    padding: 0 2rem;
    text-align: center;
    margin-top: 1.5rem;
    font-size: 1.4rem;

    @media (max-width: 800px) {
      font-size: 1.2rem;
    }
  }

  > h1 {
    > span {
      display: inline-block;
      border-bottom: 6px solid ${({ theme }) => theme.primaryLight};

      @media (max-width: 800px) {
        border-bottom: 4px solid ${({ theme }) => theme.primaryLight};
      }
    }

    text-align: center;
    font-size: 4.5rem;
    line-height: 1.05;

    padding: 0 28vw;
    
    @media (max-width: 1500px) {
      padding: 0 20vw;
    }
    
    @media (max-width: 1200px) {
      font-size: 4rem;
      padding: 0 15vw;
    }

    @media (max-width: 1000px) {
      font-size: 4rem;
      padding: 0 10vw;
    }

    @media (max-width: 800px) {
      font-size: 3rem;
      padding: 0 2rem;
    }

    @media (max-width: 450px) {
      font-size: 2.5rem;
      padding: 0 1rem;
    }

    @media (max-width: 350px) {
      font-size: 2.25rem;
      padding: 0 1rem;
    }
  }

  > button {
    margin-top: 4rem;
  }
`

const ButtonContainer = styled.div`
  ${ResponsivePadding};

  display: flex;
  flex-direction: row;
  gap: 1rem;

  font-size: 1.5rem;

  margin-top: 3rem;
  margin-bottom: 1rem;

  button {
    font-size: 1.25rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    gap: 0;

    button {
      width: 100%;
      margin-top: 1rem;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
    }
    
    a {
      width: 100%;
    }
  }
`
