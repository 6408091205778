'use client'
import styled from '@emotion/styled'
import { ResponsivePadding } from '../_components/ResponsivePadding'

export const FAQContainer = styled.div`
  ${ResponsivePadding};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  
  padding-top: 6rem;
  padding-bottom: 6rem;

  border-top: 1px solid ${({ theme }) => theme.onBackgroundLightest};

  h2 {
    font-size: 2.5rem;
    margin-bottom: 6rem;
  }
`
