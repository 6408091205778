'use client'
import { useScrollTop } from '@common/hooks/use-scroll-top'
import { Hero } from './_components/Hero'
import { ReviewCard } from './_components/ReviewCard'
import { ResponsivePadding } from './_components/ResponsivePadding'
import { IoMdStar } from 'react-icons/io'
import { ReactNode, useEffect, useState } from 'react'
import Link from 'next/link'
import { IonIcon } from '@ionic/react'
import { arrowForwardSharp } from 'ionicons/icons'
import styled from '@emotion/styled'
import Image from 'next/image'
import { Accordion } from './_components/Accordion'
import Script from 'next/script'
import { FAQPage, WithContext } from 'schema-dts'
import { FAQContainer } from './funktioner/FAQContainer'
import { FeatureSection } from './FeatureSection'

const faqs: { title: string; body: ReactNode; googleBody: string }[] = [
  {
    title: 'Behöver min BRF en hemsida?',
    body: (
      <>
        En hemsida är ett effektivt sätt att kommunicera med medlemmar, presentera viktig information och ge potentiella
        köpare och intressenter en inblick i er förening. <br />
        <br />
        Med Booet ingår en modern hemsida som är enkel att administrera. Dessutom får du tillgång till hela Booets
        plattform för att hantera allt från medlemsregister till bokningssystem.
      </>
    ),
    googleBody: `
        En hemsida är ett effektivt sätt att kommunicera med medlemmar, presentera viktig information och ge potentiella
        köpare och intressenter en inblick i er förening. <br />
        <br />
        Med Booet ingår en modern hemsida som är enkel att administrera. Dessutom får du tillgång till hela Booets
        plattform för att hantera allt från medlemsregister till bokningssystem.
    `,
  },
  {
    title: 'Ingår fri support?',
    body: (
      <>
        Ja, hos Booet ingår alltid fri support. Vi finns här för att hjälpa till med allt från tekniska frågor till att
        sätta upp er hemsida.
      </>
    ),
    googleBody: `
        Ja, hos Booet ingår alltid fri support. Vi finns här för att hjälpa till med allt från tekniska frågor till att sätta
        upp er hemsida.
    `,
  },
  {
    title: 'Vad kostar en hemsida till min BRF?',
    body: (
      <>
        Priset varierar generellt på föreningens storlek och behov. Vår prislista hittar ni <a href="/priser">här</a>.
      </>
    ),
    googleBody: `
        Priset varierar generellt på föreningens storlek och behov. Vår prislista hittar ni <a href="/priser">här</a>.
    `,
  },
]

const jsonLd: WithContext<FAQPage> = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqs.map((faq) => ({
    '@type': 'Question',
    name: faq.title,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.googleBody,
    },
  })),
}

export default function Page() {
  const [maxScroll, setMaxScroll] = useState(0)
  const scrollTop = useScrollTop()

  useEffect(() => {
    setMaxScroll(Math.max(maxScroll, scrollTop))
  }, [maxScroll, scrollTop])

  return (
    <>
      <Script
        id="faq-schema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonLd),
        }}
      />
      <Banner>
        <Hero />
        <SampleContainerV2>
          <Image src="/samples/sample_panel.webp" alt="Booets kontrollpanel" width={0} height={0} sizes="100%" />
        </SampleContainerV2>
        <SampleContainerV2>
          <div>
            <h2>
              En kontrollpanel.
              <br />
              Allt som kan tänkas behövas.
            </h2>
          </div>
        </SampleContainerV2>
        <FeatureSection />
        <ReviewsContainer>
          <div className="star-container">
            <IoMdStar />
            <IoMdStar />
            <IoMdStar />
            <IoMdStar />
            <IoMdStar />
          </div>
          <h2>Några fina ord från våra kunder</h2>
          <ReviewCard
            title={<>Booet har alltid givit oss ett mycket positivt och ödmjukt samtalsklimat</>}
            body={
              <>
                När vi har frågor har de mottagits med stor lyhördhet och resulterat i snabba åtgärder och utvecklat
                systemet enligt våra behov. Vi rekommenderar bostadsrättsföreningar som funderar på hemsida att kontakta
                Booet. <br />
                <br />
                Brf Brage i Boden önskar Booet lycka till i framtiden.
              </>
            }
            name="Urban E."
            association="BRF Brage i Boden"
            stars={5}
            source={'Trustpilot'}
            to="https://se.trustpilot.com/reviews/6582c67ba5a2292a517fb79e"
          />
          <ReviewCard
            title={<>Booet har lyckats! En frisk fläkt i myllret av verktyg som ofta är fula, krångliga och dyra.</>}
            body={
              <>
                Booet har lyckats! De har utvecklat en snygg och framför allt användarvänlig plattform för
                bostadsrättsföreningar. En frisk fläkt i myllret av verktyg som ofta är fula, krångliga och dyra.
                <br />
                <br />
                Booet är precis vad vi behövde - en innovativ lösning för att hantera föreningens admin, kommunikation
                och hemsida.
                <br />
                <br />
                Rekommenderas starkt!
              </>
            }
            name="Styrelsen"
            association="BF Gnistan"
            stars={5}
            source={'Trustpilot'}
            to="https://se.trustpilot.com/reviews/65828ef799c63277e4caefb4"
          />
          <ReviewCard
            title={<>Det är en stilren hemsida som är enkel. Enkelt att få support vid eventuella funderingar. </>}
            body={
              <>
                Det är en stilren hemsida som är enkel att handskas med som administratör. Enkelt att få support vid
                eventuella funderingar eller om ens egna minne inte tjänar väl.
              </>
            }
            name="Roman D."
            association="BRF Storspoven"
            stars={5}
            source={'Trustpilot'}
            to="https://se.trustpilot.com/reviews/6582d148a21e4a4350b9150b"
          />
          <ReviewCard
            title={
              <>
                Snygg och lättanvänd hemsida med smidiga funktioner, perfekt till vår förening. Även nya funktioner
                under utveckling och framförallt enastående och personlig service!
              </>
            }
            body={
              <>
                Snygg och lättanvänd hemsida med smidiga funktioner, perfekt till vår förening. Även nya funktioner
                under utveckling och framförallt enastående och personlig service!
              </>
            }
            name="Stefan F."
            association="BRF Henriksborg"
            stars={5}
            source={'Trustpilot'}
            to="https://se.trustpilot.com/reviews/6581cc6eca496d06b1cf2af4"
          />
          <ReviewCard
            title={
              <>
                Mycket smidig tjänst, kombinerat med en toppen kundtjänst där man alltid får hjälp och stöd vid behov!
              </>
            }
            body={
              <>
                Mycket smidig tjänst, kombinerat med en toppen kundtjänst där man alltid får hjälp och stöd vid behov!
              </>
            }
            name="Wiktor B."
            association="BRF Blåklinten"
            stars={5}
            source={'Trustpilot'}
            to="https://se.trustpilot.com/reviews/6581b4b8046feb8478ef81a8"
          />
          <ReviewCard
            title={
              <>
                Supersnabb hjälp med att sätta upp hemsidan och allt är tydligt och klart. Har man några frågor får man
                svar direkt. Kunde inte vara mer nöjd!
              </>
            }
            body={
              <>
                Supersnabb hjälp med att sätta upp hemsidan och allt är tydligt och klart. Hemsidan blev väldigt snygg
                och lätt att administrera. Har man några frågor får man svar direkt. Kunde inte vara mer nöjd!
              </>
            }
            name="Johan"
            association="BRF Strandnära"
            stars={5}
            source={'Trustpilot'}
            to="https://se.trustpilot.com/reviews/658416cc85691dcbcb31235c"
          />
        </ReviewsContainer>
        <ContrastContainer>
          <h2>En plattform skräddarsydd för och av föreningar.</h2>
          <p>
            På Booet tar vi stolthet i att lyssna på våra användare. Till skillnad från många andra leverantörer äger vi
            hela systemet och kan därför snabbt och kontinuerligt implementera nya funktioner och förbättringar. <br />
            <br />
            Vi säljer inte färdigpaketerade tredjepartslösningar - vi bygger en plattform från grunden som är
            skräddarsydd för och av föreningar.
          </p>
        </ContrastContainer>
        <SimpleSplitSection>
          <h2>
            Frågor eller funderingar? <br />
          </h2>
          <div>
            <p>
              Vi finns här för att hjälpa till. Varje förening är unik, och vi anpassar oss efter era specifika behov.
              Kontakta oss enkelt via <Link href="mailto:info@booet.se">e-post</Link> eller genom att boka en demo.
            </p>
            <Link href="/demo" style={{ marginTop: '2rem' }}>
              Boka demo <IonIcon icon={arrowForwardSharp} />{' '}
            </Link>
          </div>
        </SimpleSplitSection>
        <FAQContainer>
          <h2>Vanliga frågor</h2>
          <Accordion items={faqs} />
        </FAQContainer>
      </Banner>
    </>
  )
}

const ReviewsContainer = styled.div`
  width: 100%;
  padding: 6rem 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;

  .star-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.yellow};
  }
`

const Banner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: 10rem;

  color: ${({ theme }) => theme.onBackgroundSemiDark};

  h2 {
    width: 100%;

    text-align: center;
    font-size: 2.5rem;
    line-height: 1.05;

    margin-bottom: 3rem;

    padding: 0 0.5rem;

    @media (max-width: 1000px) {
      font-size: 2.3rem;
    }

    @media (max-width: 800px) {
      font-size: 2.15rem;
    }

    @media (max-width: 450px) {
      font-size: 2rem;
    }

    @media (max-width: 350px) {
      font-size: 1.85rem;
    }
  }

  img {
    margin-top: 4rem;
  }
`

const SampleContainerV2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 5rem;

  width: 100%;

  img {
    margin-top: 5rem;
    border-radius: 0.5rem;
    z-index: 1;
    width: 70%;

    border: 1px solid ${({ theme }) => theme.onBackgroundLightest};
    box-shadow: none;

    @media (max-width: 1000px) {
      width: 92%;
    }
  }
`

const ContrastContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 6rem 30vw;

  @media (max-width: 2000px) {
    padding: 6rem 25vw;
  }

  @media (max-width: 1500px) {
    padding: 6rem 20vw;
  }

  @media (max-width: 1000px) {
    padding: 6rem 3rem;
  }

  @media (max-width: 600px) {
    padding: 6rem 1rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  p {
    text-align: center;
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1.85rem;

    color: ${({ theme }) => theme.onBackgroundSemiLight};
  }
`

const SimpleSplitSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-top: 1px solid ${({ theme }) => theme.onBackgroundLightest};

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  padding: 8rem 0;

  ${ResponsivePadding};

  h2 {
    display: block;

    padding: 0;
    margin: 0;

    font-size: 4rem;

    @media (max-width: 1000px) {
      font-size: 3rem;
    }

    @media (max-width: 800px) {
      font-size: 2.5rem;
    }

    text-align: left;
  }

  div {
    p {
      padding: 0;
      margin: 0;

      text-align: left;
      font-size: 1.25rem;

      @media (max-width: 800px) {
        margin-top: 1rem;
        font-size: 1.15rem;
      }

      line-height: 1.5;
    }

    a {
      display: inline-flex;
      align-items: center;
      gap: 0.25rem;

      font-size: 1.25rem;

      text-decoration: underline;

      color: ${({ theme }) => theme.onBackground};

      transition: all 0.1s ease-in-out;

      :hover {
        color: ${({ theme }) => theme.primaryLight};
        gap: 0.5rem;
      }
    }

    width: 100%;
  }
`
